import React, { useState } from "react";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};

const time = Math.round(new Date().getTime() / 1000);

export default function Card({
  card_type,
  card_num,
  Approve,
  _stake,
  stakeActive,
  Total_supply,
  Pool,
  contribution,
  reward,
  userInfo,
}) {
  const [open, setOpen] = useState(false);
  const [stk, setstk] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <p style={{ marginBottom: "0", fontSize: "20px", fontWeight: "600" }}>
        $METN {card_type} Pool
      </p>
      <p style={{ marginTop: "2px", fontSize: "14px" }}>
        Min. Stake {contribution} $METN
      </p>
      <hr />
      <div className="mid_card">
        <div>
          <p>APY :</p>
          <p>{Pool.apy ? Pool.apy / 10 : 0}%</p>
        </div>
        <div>
          <p>Lockup Period :</p>
          <p>{Pool.lockPeriodInDays} Days</p>
        </div>
        <div>
          <p>$METN STAKED:</p>
          <p>{parseFloat(userInfo.total_invested / 1e18).toFixed(2)}</p>
        </div>
        <div style={{ fontWeight: "700", marginTop: "10px" }}>$METN Earned</div>
        <div style={{ fontWeight: "500", fontSize: "45px" }}>{reward}</div>
        <div style={{ marginTop: "5px" }}>
          Pool is{" "}
          {time > Pool.startDate && time < Pool.endDate ? "unlocked" : "locked"}
          <span>
            {time > Pool.startDate && time < Pool.endDate ? (
              <LockOpenOutlinedIcon />
            ) : (
              <LockOutlinedIcon />
            )}
          </span>
        </div>
      </div>
      <div style={{ marginTop: "85px" }}>
        <Button
          variant="contained"
          onClick={() => (stakeActive ? handleOpen() : Approve())}
        >
          {" "}
          {stakeActive ? "Stake" : "Enable"}
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal">
          <div className="modal_head">
            <p>How much $METN would you like to Stake?</p>
            <CancelIcon className="cross" onClick={() => handleClose()} />
          </div>
          <section>
            <p>👉 min stake of {contribution} $METN</p>

            <p>👉 $METN balance: 0.000</p>
          </section>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5px",
              marginBottom: "15px",
            }}
          >
            <div className="inputbox">
              <input
                type="number"
                placeholder="Enter number"
                value={stk > 0 ? stk : ""}
                onChange={(event) => setstk(event.target.value)}
              />

              <span
                className="max"
                onClick={() => {
                  setstk(Total_supply);
                }}
              >
                MAX
              </span>
            </div>
            <Button variant="contained" onClick={() => _stake(card_num, stk)}>
              STAKE
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
