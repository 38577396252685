import React, { useState, useEffect } from "react";
import "./App.css";
import {
  chainID,
  Stakeabi,
  StakeAddress,
  Tokenabi,
  TokenAddress,
} from "./helper";
import Web3Modal from "web3modal";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "./Card";
import truncate from "./truncate";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import logo from "./cropped-yleo-e1648563558268.png";

let web3Modal;
let provider;
let web3;
async function init() {
  console.log("initalzing");
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          137: "https://rpc.ankr.com/polygon",
        },
        network: "polygon",
      },
    },
  };

  web3Modal = new Web3Modal({
    cacheProvider: false,
    providerOptions,
  });
}

function App() {
  const [Hello, setHello] = useState({});
  const [Token, setToken] = useState({});
  const [address, setaddress] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [active, setactive] = useState(false);
  const [stakeActive, setstakeActive] = useState(false);

  const [Pool1, setPool1] = useState({});
  const [Pool2, setPool2] = useState({});
  const [Pool3, setPool3] = useState({});

  const [reward1, setreward1] = useState(0);
  const [reward2, setreward2] = useState(0);
  const [reward3, setreward3] = useState(0);

  const [userInfo1, setuserInfo1] = useState({});
  const [userInfo2, setuserInfo2] = useState({});
  const [userInfo3, setuserInfo3] = useState({});

  const [Total_supply, setTotal_supply] = useState(0);

  const [totalStaked, settotalStaked] = useState(0);
  const [totalToken, settotalToken] = useState(0);

  const [contribution1, setcontribution1] = useState(0);
  const [contribution2, setcontribution2] = useState(0);
  const [contribution3, setcontribution3] = useState(0);

  useEffect(() => {
    if (active) {
      async function fet() {
        await init();
        await loadBlockdata();
      }
      fet();
    }
  }, [active]);

  const loadBlockdata = async () => {
    try {
      provider = await web3Modal.connect();
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      return;
    }

    web3 = new Web3(provider);

    loadBlockdat();
  };
  const maxApproval = Web3.utils.toBN(
    "115792089237316195423570985008687907853269984665640564039457584007913129639935"
  );
  const loadBlockdat = async () => {
    console.log("Web3 instance is", web3);
    let chain;
    await web3.eth.getChainId().then((values) => {
      chain = values;
    });
    if (chain === chainID) {
      console.log("yes");
      const accounts = await new web3.eth.getAccounts();
      console.log(accounts[0]);
      setaddress(accounts[0]);
      const Hell = new web3.eth.Contract(Stakeabi, StakeAddress);
      setHello(Hell);
      const token = new web3.eth.Contract(Tokenabi, TokenAddress);
      setToken(token);

      const allow = await token.methods
        .allowance(accounts[0], StakeAddress)
        .call();
      // const allow = maxApproval;
      if (allow >= maxApproval) {
        console.log("more");
        setstakeActive(true);
      }
      const pool1 = await Hell.methods.poolInfo(0).call();
      setPool1(pool1);
      const pool2 = await Hell.methods.poolInfo(1).call();
      setPool2(pool2);
      const pool3 = await Hell.methods.poolInfo(2).call();
      setPool3(pool3);

      const userinfo1 = await Hell.methods.users(0, accounts[0]).call();
      setuserInfo1(userinfo1);
      const userinfo2 = await Hell.methods.users(1, accounts[0]).call();
      setuserInfo2(userinfo2);
      const userinfo3 = await Hell.methods.users(2, accounts[0]).call();
      setuserInfo3(userinfo3);

      const payout1 = await Hell.methods._payout(0, accounts[0]).call();
      setreward1(parseFloat(payout1 / 1e18).toFixed(2));
      const payout2 = await Hell.methods._payout(1, accounts[0]).call();
      setreward2(parseFloat(payout2 / 1e18).toFixed(2));
      const payout3 = await Hell.methods._payout(2, accounts[0]).call();
      setreward3(parseFloat(payout3 / 1e18).toFixed(2));

      const totalstaked =
        Math.floor(userinfo1.total_invested / 1e18) +
        Math.floor(userinfo2.total_invested / 1e18) +
        Math.floor(userinfo3.total_invested / 1e18);
      settotalStaked(totalstaked);
      setcontribution1(parseFloat(pool1.minContrib / 1e18).toFixed(0));
      setcontribution2(parseFloat(pool2.minContrib / 1e18).toFixed(0));
      setcontribution3(parseFloat(pool3.minContrib / 1e18).toFixed(0));

      const bal = await token.methods.balanceOf(accounts[0]).call();
      settotalToken(totalStaked + Math.floor(bal / 1e18));
      setTotal_supply(parseFloat(bal / 1e18));
    } else {
      setactive(false);
      alert("Connect to Polygon");
    }
    return;
  };

  const Approve = async () => {
    try {
      await Token.methods
        .approve(StakeAddress, maxApproval)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          setstakeActive(true);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const _stake = async (poolid, num) => {
    num = num * 1000000000;
    num = num + "000000000";
    try {
      await Hello.methods
        .stake(poolid, num)
        .send({ from: address })
        .on("transactionHash", (hash) => {});
    } catch (err) {
      console.log("tx rejected");
    }
  };

  return (
    <div className="App">
      <div className="head">
        <div>
          <img
            src={logo}
            alt="logo"
            onClick={() => (window.location.href = "http://metanations.net/")}
          />
        </div>
        <div>
          <button onClick={() => setactive(true)} className="stacked_btn">
            {address !== "0x0000000000000000000000000000000000000000"
              ? truncate(address)
              : "CONNECT"}
          </button>

          <AccountCircleIcon className="icon" />
        </div>
      </div>
      <div className="outer">
        <h1>NEW Staking Pools!</h1>
        <h3>Three new Staking pools are here! 🤟</h3>
        <div className="middle">
          <Card
            card_type={" Hodlers 💵 "}
            card_num={0}
            Approve={Approve}
            _stake={_stake}
            stakeActive={stakeActive}
            Total_supply={Total_supply}
            Pool={Pool1}
            contribution={contribution1}
            reward={reward1}
            userInfo={userInfo1}
          />
          <Card
            card_type={" VIP 💰 "}
            card_num={1}
            Approve={Approve}
            _stake={_stake}
            stakeActive={stakeActive}
            Total_supply={Total_supply}
            Pool={Pool2}
            contribution={contribution2}
            reward={reward2}
            userInfo={userInfo2}
          />
          <Card
            card_type={" Legends 💎 "}
            card_num={2}
            Approve={Approve}
            _stake={_stake}
            stakeActive={stakeActive}
            Total_supply={Total_supply}
            Pool={Pool3}
            contribution={contribution3}
            reward={reward3}
            userInfo={userInfo3}
          />
        </div>
        <div style={{ marginTop: "57px", marginBottom: "35px" }}>
          <span
            style={{
              borderRadius: "30px 35px 15px 15px",
              backgroundColor: "white",
              fontSize: "29px",
              fontWeight: "800",
              padding: "5px 14px",
            }}
          >
            $METN you own
          </span>
        </div>
        <div className="container">
          <div className="one">
            <span>{Total_supply}</span>
            <p>Wallet</p>
          </div>
          <div className="two">
            <span>{totalStaked}</span>
            <p>Staking</p>
          </div>
          <div className="three">
            <span>{totalToken}</span>
            <p>Total</p>
          </div>
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}

export default App;
